import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaReact } from "react-icons/fa";
import { FaAngular, FaHtml5, FaPython } from "react-icons/fa6";
import { IoLogoCss3 } from "react-icons/io";
import { IoLogoDocker, IoLogoFirebase, IoLogoSass } from "react-icons/io5";
import {
  SiDjango,
  SiExpress,
  SiJavascript,
  SiMongodb,
  SiPostgresql,
  SiShadcnui,
  SiTailwindcss,
  SiTypescript,
} from "react-icons/si";
import { TbBrandMysql, TbBrandNextjs, TbBrandNodejs } from "react-icons/tb";
import "./experience.css";

const Experience = () => {
  return (
    <section id="experience">
      <h2>The skills I have</h2>
      <h1>My experience</h1>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>HTML</h3>
                <FaHtml5 />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>CSS</h3>
                <IoLogoCss3 />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>SASS</h3>
                <IoLogoSass />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Angular</h3>
                <FaAngular />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>React</h3>
                <FaReact />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>JavaScript</h3>
                <SiJavascript />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>TypeScript</h3>
                <SiTypescript />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Shadcn</h3>
                <SiShadcnui />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Next Js</h3>
                <TbBrandNextjs />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Tailwind</h3>
                <SiTailwindcss />
              </div>
            </article>
          </div>
        </div>

        {/* END OF FRONTEND */}

        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Node JS</h3>
                <TbBrandNodejs />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Python</h3>
                <FaPython />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Django</h3>
                <SiDjango />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Next Js</h3>
                <TbBrandNextjs />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Express JS</h3>
                <SiExpress />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Docker</h3>
                <IoLogoDocker />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Mongo DB</h3>
                <SiMongodb />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>MySQL</h3>
                <TbBrandMysql />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Postgres</h3>
                <SiPostgresql />
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon" />
              <div className="logo-langage">
                <h3>Firebase</h3>
                <IoLogoFirebase />
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
