import React from "react";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import profile_picture from "../../assets/dsc04304-avec_accentuation-bruit.jpg";
import "./about.css";

const About = () => {
  return (
    <section id="about">
      <h2>Get To Know</h2>
      <h1>About Me</h1>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={profile_picture} alt="About_Hugo_image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>
                1,5 years <br /> Developer Experience
              </small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Education</h5>
              <small>Holberton School Toulouse</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>
                Several projects completed
                <br />
                and in progress
              </small>
            </article>
          </div>
          <p>
            Hello! I'm <span className="hugo_name">Hugo Castéras</span>, a
            passionate and motivated Full-Stack Web Developer based in Toulouse,
            France. With a solid educational foundation and hands-on experience,
            I am dedicated to continuously improving my skills and taking on new
            challenges in the field of web development.
          </p>
          <p>
            I began my educational journey with a BTS in Ortho-Prothesis from
            Lycée Anne Veaute in Castres (2020-2022). Currently, I am furthering
            my knowledge and expertise at Holberton School Toulouse, where I
            have been pursuing a web development course since February 2023.
          </p>
          <p>
            My professional experiences are diverse, reflecting my adaptability
            and willingness to take on new roles. I collaborated with the
            start-up Twelv from May to July 2024, contributing to innovative
            projects. Additionally, I have experience in customer service,
            having worked as a server and bartender at French Burger in Labège
            from August 2022 to January 2023. During the summers of 2020 and
            2021, I also worked as a harvest agent for Toulouse Métropole.
          </p>
          <p>
            My projects showcase my technical skills and my ability to develop
            practical applications. One notable project is Depistclic, a web
            application designed to aid in the early detection of complications
            in patients with type 2 diabetes. I also developed a professional
            training application using Java for the back-end and Angular for the
            front-end. Recently, I completed an e-commerce website for a local
            bakery in June 2024.
          </p>
          <p>
            Outside of my professional life, I enjoy staying active with sports
            like football, tennis, skiing, and weightlifting. I also have a
            passion for cooking and continuously seek to improve my
            communication and problem-solving skills through Agile methods and
            time management practices.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
