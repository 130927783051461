import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/depistclic.png";
import IMG2 from "../../assets/Twelv Logo.png";
import IMG3 from "../../assets/simpleshell.png";
import IMG4 from "../../assets/3D-Card-Effect.png";
import IMG5 from "../../assets/snake_game.png";
import IMG6 from "../../assets/tictactoe2.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "DepistClic",
    github: "https://github.com/hug0-cstrs/DepistClic",
    demo: "https://www.depistclic.fr/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Twelv",
    github: "",
    demo: "https://twelv.fr/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Simple Shell",
    github: "https://github.com/hug0-cstrs/holbertonschool-simple_shell",
    demo: "https://github.com/hug0-cstrs/holbertonschool-simple_shell",
  },
  {
    id: 4,
    image: IMG4,
    title: "3D Card Hover Effect",
    github: "https://github.com/hug0-cstrs/Projet_perso/tree/main/3D%20Card%20Hover%20Effect",
    demo: "https://github.com/hug0-cstrs/Projet_perso/tree/main/3D%20Card%20Hover%20Effect",
  },
  {
    id: 5,
    image: IMG5,
    title: "Snake Game",
    github: "https://github.com/hug0-cstrs/Projet_perso/tree/main/Snake%20Game",
    demo: "https://github.com/hug0-cstrs/Projet_perso/tree/main/Snake%20Game",
  },
  {
    id: 6,
    image: IMG6,
    title: "Tic Tac Toe",
    github: "https://github.com/hug0-cstrs/Projet_perso/tree/main/tic-tac-toe",
    demo: "https://github.com/hug0-cstrs/Projet_perso/blob/main/tic-tac-toe/README.md",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2>My most recent work</h2>
      <h1>Portfolio</h1>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title}/>
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} target="_blank" rel="noreferrer" className="btn">
                  Github
                </a>
              </div>
              <div className="portfolio__item-cta">
                <a href={demo} target="_blank" rel="noreferrer" className="btn btn-primary">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;